    .value-icon-container {
        display: flex;
        gap: 2rem;
    }

    /* body {
        background-image: url("../asset/background.jpg") !important;
        background: red !important;
    } */

    .card-container {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .brri-card-value {
        font-size: 30px;
    }

    .brri-card-title {
        font-size: 15px;
    }

    .brri-icon>img {
        height: 35px;
    }

    .brri-graph-container {
        padding: 15px;
    }

    .brri-graph-icon>img {
        height: 70px;
    }

    .brri-graph-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .brri-graph-value {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    /* 
    .brri-unit {
        font-size: 10px;
        font-weight: normal;
    } */

    .brri-graph-title {
        font-weight: bold;
    }

    .soil-sensor-title,
    .soil-value {
        font-weight: bold;
    }

    .soil-card-title {
        text-align: center;
    }

    .brri-soil-container {
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }

    .soil-icon>img {
        height: 50px;
    }

    @media only screen and (max-width: 1775px) {

        .soil-sensor-title,
        .soil-value {
            font-size: 10px;
        }
    }

    @media only screen and (max-width: 767px) {

        .soil-sensor-title,
        .soil-value {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 1775px) {
        .brri-card-value {
            font-size: 20px;
        }
    }